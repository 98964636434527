// This is a tiny set of util classes in Tailwind format, if we need more utils we might add the whole suite to the project
.tw {
  &-p {
    &t-4 {
      padding-top: 1rem;
    }
    &l-8 {
      padding-left: 2rem;
    }
  }

  &-bg-red-100 {
    background-color: #f7d8d6;
  }
}
